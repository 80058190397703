import { ShareLink } from 'social-media-sharing'

export const postsSharing = () => {
    const sharingElements = document.querySelectorAll('.singlePostPage__sidebar--sharing__item > a');
    if(sharingElements){
        sharingElements.forEach(item => {
            let source = item.getAttribute('share-type');
            if(source){
                let socialMediaLink = new ShareLink(source);
                let postTitle = item.getAttribute('post-title');
                let postUrl = item.getAttribute('post-url');
                let properties = {};
                switch(source){
                    case 'facebook':
                        properties = {
                            u: postUrl,
                            quote: postTitle
                        };
                        break;
                    case 'twitter':
                        properties = {
                            url: postUrl,
                            text: postTitle
                        };
                        break;
                    case 'reddit':
                        properties = {
                            url: postUrl,
                            title: postTitle
                        };
                        break;
                    case 'telegram':
                        properties = {
                            url: postUrl,
                            text: postTitle
                        };
                        break;
                    case 'googleplus':
                        properties = {
                            url: postUrl
                        };
                        break;
                    case 'linkedin':
                        properties = {
                            url: postUrl,
                            title: postTitle
                        };
                        break;
                }
                let endpointLink = socialMediaLink.get(properties);
                if(endpointLink){
                    item.href = endpointLink;
                }
            }
        })
    }
}