import fontawesome from "@fortawesome/fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas, faOtter } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { navInit } from "./modules/navigations";
import { galleryInit } from "./modules/gallery";
import { googleMap } from "./modules/googleMaps";
import { blogCategorySliderAndSidebar } from "./modules/blogCategorySliderAndSidebar";
import { postsSharing } from "./modules/postsSharing";
import { postsTableOfContents } from "./modules/postsTableOfContents";
import { initSingleCaseStudyList } from "../../includes/blocks/block_single_case_study/script";
import { gsapAnimations } from "./scripts/gsapfunctions";
import { singlePostsSidebar } from "./modules/singlePostsSidebar";
import { caseStudiesSidebar } from'./modules/caseStudiesSidebar';

import { section_offer_page_tab } from "./../../includes/blocks/block_offers_first_step_tabs/file";
import { section_offer_page_image_content_scrolllable } from "./../../includes/blocks/block_text_with_image_offer_page/file";
import { blockCaseStudiesVerticalTabs } from "./../../includes/blocks/block_case_studies_vertical_tabs/flle";
import { blockBlogListPost } from "./../../includes/blocks/block_blog_list_post/flle";
import { sectionTabStaff } from "./../../includes/blocks/block_tabs_with_staff/file";
import { blockSliderLogos } from "./../../includes/blocks/block_slider_logos/file";
import { blockSliderReviews } from "./../../includes/blocks/block_slider_reviews/file";
import { blockiInstagram } from "./../../includes/blocks/block_instagram/file";
import { maxroyKidneyAnimationsScript } from "./modules/animationKidney";
import {caseStudiesArchiveSimpleTextAndLogoScripts} from "../../includes/blocks/block_case_studies_archive_simple_text_and_logo/scripts";
fontawesome.library.add(far, fab, fas);

if(document.querySelector('.menu-item-has-children')){
  navInit();
}
if(document.querySelector('.splide-products, .splide-mainBanner, .blockSliderText__sliders')){
  galleryInit();
}
if(document.querySelector('.blockTextWithImage')){
  gsapAnimations();
}
if(document.querySelector('#googleMap')){
  googleMap();
}
if(document.querySelector(`.blog .sectionBannerSmall__categories--slider,
.archive .sectionBannerSmall__categories--slider,
.search .sectionBannerSmall__categories--slider`)){
  blogCategorySliderAndSidebar();
}

if(document.querySelector('#maxroy_kidney')){
  maxroyKidneyAnimationsScript();
}
if(document.querySelector('.singlePostPage__sidebar')){
  singlePostsSidebar();
}
if(document.querySelector('.singlePostPage__sidebar--sharing__item')){
  postsSharing();
}
if(document.querySelector('.singlePostPage__sidebar--table-of-contents ul')){
  postsTableOfContents();
}
if(document.querySelector('.blockSingleCaseStudy')){
  initSingleCaseStudyList();
}
if(document.querySelector('.CaseStudiesPage .blockCaseStudiesArchiveSimpleTextAndLogo__content--right__relative')){
  caseStudiesSidebar();
}

if(document.querySelector('.blockCaseStudiesArchiveSimpleTextAndLogo__content--right__splide')){
  caseStudiesArchiveSimpleTextAndLogoScripts();
}

jQuery(document).ready(function ($) {
  // smooth scroll to ID
  $('.menu-item a[href*="#"]').on("click", function (e) {
    e.preventDefault();

    $("html, body").animate(
      {
        scrollTop: $($(this).attr("href")).offset().top - 120, // choose what you need
      },
      500,
      "linear"
    );
  });
});


const mainSubMenu = document.querySelector(".big-submenu");
const rightBox = document.querySelector(".right-box");
if (mainSubMenu) {
  const subMenu = mainSubMenu.querySelectorAll(".sub-menu");
  subMenu.forEach(item => {
    item.appendChild(rightBox.cloneNode(true));
  })
}

if(document.querySelector(".blockOffersFirstStepTabs")){
  section_offer_page_tab();
}
//
if(document.querySelector(".blockTextWithImageOffer")){
  section_offer_page_image_content_scrolllable();
}
if(document.querySelector('.blockCaseStudiesVerticalTabs')){
  blockCaseStudiesVerticalTabs();
}
if(document.querySelector('.blockBlogListPost')){
  blockBlogListPost();
}
if(document.querySelector('.blockTabsWithStaff')){
  sectionTabStaff();
}

if(document.querySelector('.blockSliderLogos')){
  blockSliderLogos();
}
if(document.querySelector('.blockSliderReviews')){
  blockSliderReviews();
}
if(document.querySelector('.blockInstagram')){
  blockiInstagram();
}
