import Splide from "@splidejs/splide";
import {gsap} from "gsap";

export const blogCategorySliderAndSidebar = () => {
    const sliders = document.querySelectorAll(`.blog .sectionBannerSmall__categories--slider,
.archive .sectionBannerSmall__categories--slider,
.search .sectionBannerSmall__categories--slider`);
    if(sliders){
        sliders.forEach(item => {
            new Splide(item, {
                height: "64px",
                type: "loop",
                perPage: 8,
                perMove: 1,
                pagination: false,
                breakpoints: {
                    992: {
                       perPage: 4
                    },
                    576: {
                        perPage: 2
                    }
                }
            }).mount();
        })
    }
    const sidebarRelative = document.querySelector('.customArchivePage .blog__sidebar--relative');
    const mainWrapperBlog = document.querySelector('.customArchivePage .blog__wrapper');
    if(sidebarRelative && window.innerWidth > 1200){
        gsap.to(sidebarRelative, {scrollTrigger: {
            trigger: mainWrapperBlog,
            pin: sidebarRelative,
            start: 'top 132px',
            //end: 'bottom bottom',
            end: () => `+=${mainWrapperBlog.clientHeight - 1050}`,
            //toggleActions: 'play reverse none reverse',
        }});
    }
}