export const sectionTabStaff = () => {

    window.addEventListener("DOMContentLoaded", () => {
        let tabs = document.querySelectorAll('.blockTabsWithStaff');
        tabs.forEach(block=>{
            const tabsTitles = block.querySelectorAll('.blockTabsWithStaff__single-category');
            const tabsSingleTabs = block.querySelectorAll('.blockTabsWithStaff__single-tabs');
            const tabsSmallTabs = block.querySelectorAll('.blockTabsWithStaff__single-small-boxs');
            tabsTitles.forEach(tab=>{
              tab.addEventListener('click', ()=>{
                removeClassInElements(tabsTitles, 'active');
                removeClassInElements(tabsSingleTabs, 'active');
                tab.classList.add('active');
                block.querySelector('.blockTabsWithStaff__single-tabs[data-id="' + tab.getAttribute('data-id') + '"]').classList.add('active');
              })
            });
            tabsSmallTabs.forEach(smallTabs=>{
                smallTabs.addEventListener('click', ()=>{
                  removeClassInElements(tabsSmallTabs, 'active');
                  smallTabs.classList.add('active');
                 
                })
            });
        })
        const removeClassInElements = (elementsArray, classToRemove) => {
            elementsArray.forEach(element => {
              element.classList.remove(classToRemove);
            })
        }

    });
}