import Splide from "@splidejs/splide";
export function blockSliderLogos() {
  
  var blockSliderLogosSliders = document.getElementsByClassName(
    "blockSliderLogos__sliders"
  );
  for (var i = 0, len = blockSliderLogosSliders.length; i < len; i++) {
    new Splide(blockSliderLogosSliders[i], {
      //cover: true,
      type: "loop",
      trimSpace: false,
      focus: 'center',
      perPage: 5,
      perMove: 1,
      padding: {
        left : 0,
        right: '10em',
      },
      breakpoints: {
        992: {
          perPage: 3,
          focus: 'center',
          trimSpace: false,
          padding:{
            right:0,
          }
        },
        576: {
          perPage: 2,
          focus: 'center',
          trimSpace: false,
          padding:{
            right:0,
          }
        },
        460: {
          perPage: 1,
          focus: 'center',
          trimSpace: false,
          padding:{
            right:0,
          }
        }
      }
    }).mount();
  }

}