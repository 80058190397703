export const blockCaseStudiesVerticalTabs = () => { 
  window.addEventListener("DOMContentLoaded", () => {
    let tabs = document.querySelectorAll('.blockCaseStudiesVerticalTabs');
    tabs.forEach(block=>{
        const tabsSmallTabs = block.querySelectorAll('.blockCaseStudiesVerticalTabs__single-small-boxs');
        tabsSmallTabs.forEach(smallTabs=>{
            smallTabs.addEventListener('click', ()=>{
              removeClassInElements(tabsSmallTabs, 'active');
              smallTabs.classList.add('active');
             
            })
        });
    })
    const removeClassInElements = (elementsArray, classToRemove) => {
        elementsArray.forEach(element => {
          element.classList.remove(classToRemove);
        })
    }
});
}