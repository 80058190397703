import Splide from "@splidejs/splide";

export const caseStudiesArchiveSimpleTextAndLogoScripts = () => {
    const sliders = document.querySelectorAll('.blockCaseStudiesArchiveSimpleTextAndLogo__content--right__splide');

    if (sliders) {
        sliders.forEach(slider => {
            let caseStudieSlider = new Splide(slider, {
                arrows: true,
                pagination: false,
                type: "loop",
                perPage: 1,
                perMove: 1,
            }).mount();
        });
    }
}