import {gsap} from "gsap";

export const caseStudiesSidebar = () => {
  const mainWrapper = document.querySelector('.CaseStudiesPage');
  const sidebar = mainWrapper.querySelector('.blockCaseStudiesArchiveSimpleTextAndLogo__content--right__relative');

  if(sidebar && window.innerWidth > 1200){

    gsap.to(sidebar, {scrollTrigger: {
        trigger: mainWrapper,
        pin: sidebar,
        start: '70px 132px',
        //end: 'bottom bottom',
        end: () => `+=${mainWrapper.clientHeight - 960}`,
        //toggleActions: 'play reverse none reverse',
      }});
  }
}
