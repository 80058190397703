import {gsap} from "gsap";

export const singlePostsSidebar = () => {
    const sidebarRelative = document.querySelector('.singlePostPage .singlePostPage__sidebar--relative');
    const mainWrapperBlog = document.querySelector('.singlePostPage article');
    const commentsForm = document.querySelector('.content__comments');
    if(sidebarRelative && window.innerWidth > 1200){
        let commentFormHeight = 0;
        if(commentsForm) commentFormHeight = commentsForm.clientHeight + 60;

        gsap.to(sidebarRelative, {scrollTrigger: {
            trigger: mainWrapperBlog,
            pin: sidebarRelative,
            start: 'top 132px',
            //end: 'bottom bottom',
            end: () => `+=${mainWrapperBlog.clientHeight - 550 - commentFormHeight}`,
            //toggleActions: 'play reverse none reverse',
        }});
    }
}
