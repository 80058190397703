import Splide from "@splidejs/splide";
export function blockSliderReviews() {
  
  var blockSliderReviews = document.getElementsByClassName(
    "blockSliderReviews__sliders"
  );
  for (var i = 0, len = blockSliderReviews.length; i < len; i++) {
    new Splide(blockSliderReviews[i], {
      type: "loop",
     // trimSpace: false,
      //fixedWidth: '54vw',
      autoWidth: true,
      focus    : 'center',
      perPage: 3,
      perMove: 1,
      breakpoints: {
        768: {
          perPage: 1,
          trimSpace: false,
          autoWidth: false,
        }
      }
     

    }).mount();
  }

}