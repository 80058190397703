import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const section_offer_page_image_content_scrolllable = () => {
  window.addEventListener('DOMContentLoaded', () => {
    const mainWrapper = document.querySelector('.blockTextWithImageOffer .textPhoto__wrapper');
    const imagesWrapper = document.querySelector('.blockTextWithImageOffer .textPhoto__imagesBox');
    const textSingle = document.querySelectorAll('.blockTextWithImageOffer .textPhoto__textBox');

    gsap.to(imagesWrapper, {scrollTrigger: {
      trigger: mainWrapper,
      pin: imagesWrapper,
      start: 'top 77',
      end: 'bottom bottom',
    }});  

    const changePoint = (element) => {
      const id = element.getAttribute('id');
      document.querySelectorAll('.textPhoto__imageBox').forEach((item) => {
        (item.getAttribute('id') === id) ? item.classList.add('active') : item.classList.remove('active');
      })
    }

    textSingle.forEach(element => {
      gsap.to(element, {scrollTrigger: {
        trigger: element,
        start: 'top 50%',
        end: 'bottom 50%',
        onEnter: () => {
          changePoint(element);
        },
        onEnterBack: () => {
          changePoint(element);
        }
      }});  
    })

  })
}

