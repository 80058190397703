import { gsap } from "gsap";

export const maxroyKidneyAnimationsScript = () => {
    window.addEventListener("DOMContentLoaded", () => {
        const kidneys = document.querySelectorAll('#maxroy_kidney');
        kidneys.forEach(item => {
            let maxroy_kidneyLine_1 = item.querySelector('#maxroy_kidneyLine_1');
            let maxroy_kidneyLine_2 = item.querySelector('#maxroy_kidneyLine_2');
            let maxroy_kidneyLine_3 = item.querySelector('#maxroy_kidneyLine_3');
            let maxroy_kidneyLine_4 = item.querySelector('#maxroy_kidneyLine_4');

            let tl = gsap.timeline({ repeat: -1, repeatRefresh: true });

            tl.to(maxroy_kidneyLine_1, { x: 0, y: 0, duration: 2.2 }, 0)
                .to(maxroy_kidneyLine_2, { x: 0, y: 0, duration: 2.2 }, 0)
                .to(maxroy_kidneyLine_3, { x: 0, y: 0, duration: 2.2 }, 0)
                .to(maxroy_kidneyLine_4, { x: 0, y: 0, duration: 2.2 }, 0);

            tl.to(maxroy_kidneyLine_1, { x: 15, y: -15, duration: 2.2 }, 1.3)
                .to(maxroy_kidneyLine_2, { x: 15, y: 15, duration: 2.2 }, 1.3)
                .to(maxroy_kidneyLine_3, { x: -15, y: 15, duration: 2.2 }, 1.3)
                .to(maxroy_kidneyLine_4, { x: -15, y: -15, duration: 2.2 }, 1.3);

            tl.to(maxroy_kidneyLine_1, { x: 0, y: 0, duration: 2.2 }, 2.6)
                .to(maxroy_kidneyLine_2, { x: 0, y: 0, duration: 2.2 }, 2.6)
                .to(maxroy_kidneyLine_3, { x: 0, y: 0, duration: 2.2 }, 2.6)
                .to(maxroy_kidneyLine_4, { x: 0, y: 0, duration: 2.2 }, 2.6);

            tl.to(maxroy_kidneyLine_1, { x: -15, y: 15, duration: 2.2 }, 3.9)
                .to(maxroy_kidneyLine_2, { x: -15, y: -15, duration: 2.2 }, 3.9)
                .to(maxroy_kidneyLine_3, { x: 15, y: -15, duration: 2.2 }, 3.9)
                .to(maxroy_kidneyLine_4, { x: 15, y: 15, duration: 2.2 }, 3.9);
        })
    });
};
