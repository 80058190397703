import Splide from "@splidejs/splide";

export function galleryInit() {
  var elms = document.getElementsByClassName("splide-products");
  if(elms){
    for (var i = 0, len = elms.length; i < len; i++) {
      new Splide(elms[i], {
        height: "30rem",
        cover: true,
        type: "loop",
        perPage: 2,
        perMove: 1,
      }).mount();
    }
  }

  var mainBanner = document.getElementsByClassName("splide-mainBanner");
  if(mainBanner){
    for (var i = 0, len = mainBanner.length; i < len; i++) {
      new Splide(mainBanner[i], {
        height: "30rem",
        cover: true,
        type: "loop",
        perPage: 1,
        perMove: 1,
      }).mount();
    }
  }

  var blockSliderText = document.getElementsByClassName(
    "blockSliderText__sliders"
  );
  if(blockSliderText){
    for (var i = 0, len = blockSliderText.length; i < len; i++) {
      new Splide(blockSliderText[i], {
        height: "30rem",
        cover: true,
        type: "loop",
        perPage: 1,
        perMove: 1,
      }).mount();
    }
  }
}
