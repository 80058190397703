import Splide from "@splidejs/splide";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const blockBlogListPost = async () => { 
  var blockBlogListPost = document.getElementsByClassName("splide-blockBlogListPost");
  for (var i = 0, len = blockBlogListPost.length; i < len; i++) {
    new Splide(blockBlogListPost[i], {
      height: "16px",
      fixedWidth: 'auto',
      type: "loop",
      perPage: 10,
      perMove:1,
      focus    : 'center',
      pagination: false,
      padding: {
        left : '20px',
      }
    }).mount();
  }

  class State {
    constructor() {
      this._category = '';
    }
    setCategory(category) {
      this._category = category;
    }
    getCategory() {
      return this._category;
    }
  }

  let app = new State();
  let categoriesButtons = document.querySelectorAll('.blockBlogListPost .categories__singleElement');
  let blog = document.getElementById('blockBlogListPost');
  
  
  const resetState = () => {
    app.setCategory('');
    
  }
  
  const clearBlog = () => {
    blog.innerHTML = '';
  }
  categoriesButtons.forEach((categoriesButton) => {
    categoriesButton.addEventListener('click', () => {
     
      const catId = categoriesButton.getAttribute('data-id');
      resetState();
      (catId != 'all') ? app.setCategory(catId): app.setCategory();
      getPosts();
    })
  })

  const getPosts = ( category = app.getCategory()) => {
    jQuery(document).ready(function ($) {
     
      $.ajax({
        type: "POST",
        dataType: "json",
        url: ajax.url,
        data: {
          action: "generatedPostList",
          category: category,
        },
        success: function(response) {
          clearBlog();
          console.log(response)
          Object.keys(response['posts']).forEach(key => {
            // console.log(response['posts'][key]);
            blog.innerHTML += response['posts'][key]; // the value of the current key.
          });
        },
        cache: false
      });
    });
  }
  window.addEventListener('DOMContentLoaded', () => {
    const mainWrapperBlog = document.querySelector('.blockBlogListPost__list--desktop');
    const sideBarBlog = document.querySelector('.blockBlogListPost__sidebar--desktop');
    const paginationBox = document.querySelector('.paginationBox');
    const prevArrow = document.querySelector('.paginationBox .prev.page-numbers');
    if(prevArrow == null){
      const firstPaginationElement = document.querySelector('.paginationBox .page-numbers.current');
      const prevArrowContent = document.createElement("span");
      prevArrowContent.setAttribute("class", "page-numbers prev");
      const prevArrowContent_content = document.createTextNode("←");
      prevArrowContent.appendChild(prevArrowContent_content);
      paginationBox.insertBefore(prevArrowContent, firstPaginationElement);
    }


    gsap.to(sideBarBlog, {scrollTrigger: {
      trigger: mainWrapperBlog,
      pin: sideBarBlog,
      start: 'top top',
      //end: 'bottom bottom',
      end: () => `+=${mainWrapperBlog.clientHeight}`,
     //toggleActions: 'play reverse none reverse',
    }});
  })
}


