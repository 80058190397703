export const initSingleCaseStudyList = () => {
    const listItems = document.querySelectorAll('.blockSingleCaseStudy .header__list a');
    if(listItems){
        listItems.forEach(item => {
            let id = item.hash;
            if(id){
                item.addEventListener('click', e => {
                    let posY = document.querySelector(`div${id}`).offsetTop;
                    console.log(posY)
                    e.preventDefault()
                    window.scrollTo({
                        top: posY,
                        left: 0,
                        behavior: 'smooth'
                    });
                })
            }
        })
    }
}