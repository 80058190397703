import {gsap} from "gsap";

const headerMobile = document.querySelector('#headerMobile');

export function navInit() {

    if(headerMobile){
        const itemsHasMenu = headerMobile.querySelectorAll('.menu-item-has-children');

        headerMobile.querySelector('.hamburger').addEventListener('click', () => {
            let headerBurger = headerMobile.querySelector('.hamburger');
            if(headerMobile.classList.contains('is-active')){
                headerMobile.classList.remove('is-active');
                headerBurger.classList.remove('is-active');
            } else {
                headerMobile.classList.add('is-active');
                headerBurger.classList.add('is-active');
            }
        })

        if(itemsHasMenu){
            itemsHasMenu.forEach(item=> {
                let menuOpener = item.querySelectorAll('.menu-item-opener');
                if(menuOpener){
                    menuOpener.forEach(opener => {
                        opener.addEventListener('click', (e) => {
                            e.preventDefault();
                            if(window.innerWidth <= 1340){
                                if(item.classList.contains('open')){
                                    item.classList.remove('open');
                                } else {
                                    itemsHasMenu.forEach(item => {
                                        item.classList.remove('open');
                                    })
                                    item.classList.add('open');
                                    if(window.innerWidth > 1000) {

                                        const menuItems = item.querySelectorAll('.menu-item .menu-item-has-children');
                                        let tl =  gsap.timeline();
                                        menuItems.forEach(element=>{
                                            tl.fromTo(element, {opacity: 0, x: -20}, {opacity: 1, duration: 0.1, x: 0});
                                        })
                                    }
                                }
                            }
                        })
                        let opened = false;
                        item.addEventListener('mouseover', (e) => {
                            if (window.innerWidth > 1340 && !opened) {
                                opened = true;
                                const menuItems = item.querySelectorAll('.menu-item .menu-item-has-children');
                                let tl = gsap.timeline();
                                menuItems.forEach(element=>{
                                    tl.fromTo(element, {opacity: 0, x: -20}, {opacity: 1, duration: 0.1, x: 0});
                                })
                            }
                        })
                        item.addEventListener('mouseleave', (e) => {
                            opened = false;
                        })
                    })
                }

                const children = item.querySelectorAll('.sub-menu a');
                children.forEach(child=>{
                    child.addEventListener('click', e=>{
                        e.stopPropagation();
                    })
                })
            })
        }
    }

    let readProgressDesktop = document.querySelector('#post-progressbar--desktop');
    let readProgressMobile = document.querySelector('#post-progressbar--mobile');
    if(readProgressDesktop && readProgressMobile){
        document.addEventListener('scroll', () => {
            let posY = window.pageYOffset;
            let height = document.body.offsetHeight;
            let footerHeight = document.querySelector('footer.footer').offsetHeight + 68;
            let progress = Math.floor((posY / (height - footerHeight)) * 100) + 1;
            readProgressDesktop.querySelector('span').style.width = `${progress}%`;
            readProgressMobile.querySelector('span').style.width = `${progress}%`;
        })
    }
}
