import { Loader } from '@googlemaps/js-api-loader';

let mapPlace = '',
    mapMarkerPosition = {},
    mapOptions = {},
    gmLat = 0,
    gmLng = 0;

const loader = new Loader({
    apiKey: "AIzaSyD_S31MLc7cMOyLVCwUhO9srdqYWMeFmiI",
    version: "weekly",
    libraries: ["places"]
});

const loadData = () => {
    return new Promise((resolve, reject) => {
        mapPlace = document.getElementById('googleMap');
        gmLat = 52.4089528;
        gmLng = 16.9258778;

        mapOptions = {
            center: {
                lat: gmLat,
                lng: gmLng
            },
            disableDefaultUI: true,
            zoom: 14,
        };

        mapMarkerPosition = {
            lat: gmLat,
            lng: gmLng
        };
    })
};

export const googleMap = async () => {
    if(document.getElementById('googleMap')){
        loadData().then(
            loader.loadCallback(e => {
                if(e){
                    console.log('Loading Google Map: Failed')
                } else{
                    const map = new google.maps.Map(document.getElementById('googleMap'), mapOptions);
                    const marker = new google.maps.Marker({
                        position: mapMarkerPosition,
                        map: map
                    })
                }
            })
        )
    }
}