import Splide from "@splidejs/splide";
export function blockiInstagram() {
  const blockInstagramSections = document.querySelectorAll(".blockInstagram");
  const blockiInstagramSliders = document.querySelectorAll(".blockInstagram__sliders");

  function insertAfter(referenceNode, newNode) {
    referenceNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  if(blockInstagramSections && blockiInstagramSliders){
    blockInstagramSections.forEach((element) =>{
      let listImage = element.querySelectorAll('.meks-instagram-widget a');
      let newListImageContent = element.querySelector('splide__list');

      if(listImage && newListImageContent){
        listImage.forEach((singleImage) => {
          let node = document.createElement("LI");
          node.setAttribute("class", "splide__slide blockInstagram__slider");
          insertAfter(node, singleImage);
          newListImageContent.appendChild(node);
        })
      }
    });

    for (let i = 0, len = blockiInstagramSliders.length; i < len; i++) {
      new Splide(blockiInstagramSliders[i], {
        type: "loop",
        trimSpace: false,
        focus    : 'center',
        perPage: 5,
        perMove:1,
        padding: {
          left : 0,
          right: '10em',
        },
        breakpoints: {
          992: {
            perPage: 1,
            height: "50vw",

            fixedWidth: '100vw',
            focus    : 'center',
            trimSpace: false,
            padding:{
              right:0,
            }
          }
        }
      }).mount();
    }
  }

}