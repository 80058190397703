export const postsTableOfContents = () => {
    const tableOfContents = document.querySelector('.singlePostPage__sidebar--table-of-contents--desktop ul');
    const tableOfContentsMobile = document.querySelector('.singlePostPage__sidebar--table-of-contents--mobile');
    const article = document.querySelector('.singlePostPage article');
    if(tableOfContents && article && tableOfContentsMobile){
        let allH3Elements = article.querySelectorAll('h2,h3,h4');
        if(allH3Elements){
            allH3Elements.forEach(item => {
                if(item.parentNode.classList.contains('singlePostPage__title')) return;
                if(item.id === "reply-title") return;

                let element = document.createElement('li');
                let elementMobile = document.createElement('li');

                element.innerHTML = item.innerText;
                elementMobile.innerHTML = item.innerText;

                element.addEventListener('click', () => {
                    let posY = item.offsetTop - 68;
                    window.scrollTo({
                        top: posY,
                        left: 0,
                        behavior: 'smooth'
                    });
                })
                elementMobile.addEventListener('click', () => {
                    let posY = item.offsetTop - 68;
                    window.scrollTo({
                        top: posY,
                        left: 0,
                        behavior: 'smooth'
                    });
                })
                tableOfContents.appendChild(element);
                tableOfContentsMobile.querySelector('ul').appendChild(elementMobile);
            })
        }

        tableOfContentsMobile
            .querySelector('span.singlePostPage__sidebar--table-of-contents--mobile__opener')
            .addEventListener('click', e => {
                tableOfContentsMobile.querySelector('ul').classList.toggle('opened');
                e.target.classList.toggle('opened');
            });
    }
}
